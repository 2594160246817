(function ($) {
    'use strict';
    $(function () {
        var body = $('body');
        var contentWrapper = $('.content-wrapper');
        var scroller = $('.container-scroller');
        var footer = $('.footer');
        var sidebar = $('.sidebar');
        var mainMenu = $("[data-menu='main']");


        function handleRouteChange() {

            var module = location.pathname.split("/").filter(v => v)[0];
            var current = location.pathname.split("/").filter(v => v)[1];


            $("#tp" + module).each(function () {
                var $this = $(this)
                const parent = $this.parents('.nav-item').last()
                parent.addClass('active');
                //find sub menus
                const subMenus = parent.find(".sub-menu").first();
                if (!subMenus?.length) return;
                subMenus.closest('.collapse').addClass('show');
            })

        }

        // Listen for back/forward navigation
        $(window).on("popstate", function () {
            handleRouteChange();
        });

        // Override pushState & replaceState to detect route changes
        (function (history) {
            var pushState = history.pushState;
            var replaceState = history.replaceState;

            history.pushState = function () {
                pushState.apply(history, arguments);
                $(window).trigger("routeChange");
            };

            history.replaceState = function () {
                replaceState.apply(history, arguments);
                $(window).trigger("routeChange");
            };
        })(window.history);

        // Custom event to detect route change
        $(window).on("routeChange", function () {
            handleRouteChange();
        });

        handleRouteChange()


        // $(window).on("popstate", function () {
        //     console.log("state", location.pathname)
        // });
        // mainMenu.on('click', function () {
        //     console.log(current, module)
        //     if (this.classList.contains('activeDash')) return;
        //     $("[data-menu='main']").each((i, elem) => {
        //         const element = $(elem);
        //         element.removeClass('activeDash');
        //         element.attr('aria-expanded', 'false');
        //         element.parents('.nav-item').find('.show').removeClass('show');
        //     });
        //     if (!this.classList.contains('activeDash')) {
        //         this.classList.add('activeDash');
        //     }
        // });
        //Add active class to nav-link based on url dynamically
        //Active class can be hard coded directly in html file also as required

        function addActiveClass(element) {

            if (current === '') {
                //for root url
                if (element.attr('href').indexOf('index.html') !== -1) {
                    element.parents('.nav-item').last().addClass('active');
                    if (element.parents('.sub-menu').length) {
                        element.closest('.collapse').addClass('show');
                        element.addClass('active');
                    }
                }
            } else {
                //for other url

                if (current.toLowerCase() === element.attr('id')?.toLowerCase()) {
                    element.parents('.nav-item').last().addClass('active');
                    if (element.parents('.sub-menu').length) {
                        element.closest('.collapse').addClass('show');
                        element.addClass('active');
                    }
                    if (element.parents('.submenu-item').length) {
                        element.addClass('active');
                    }
                }

                if (element.attr('href').indexOf(current) !== -1) {
                    //console.log(element.parents('.nav-item').last())
                    element.parents('.nav-item').last().addClass('active');
                    if (element.parents('.sub-menu').length) {
                        element.closest('.collapse').addClass('show');
                        element.addClass('active');
                    }
                    if (element.parents('.submenu-item').length) {
                        element.addClass('active');
                    }
                }
            }
        }

        var current = location.pathname
            .split('/')
            .slice(-1)[0]
            .replace(/^\/|\/$/g, '');

        var module = location.pathname.split("/").filter(v => v)[0];
        var current = location.pathname.split("/").filter(v => v)[1];

        // $('.nav li a', sidebar).each(function () {
        //     var $this = $(this);

        //     addActiveClass($this);
        // });

        // $("#" + module).each(function () {
        //     var $this = $(this)
        //     const parent = $this.parents('.nav-item').last()
        //     parent.addClass('active');
        //     //find sub menus
        //     const subMenus = parent.find(".sub-menu").first();
        //     if (!subMenus?.length) return;
        //     subMenus.closest('.collapse').addClass('show');
        // })
        $('.horizontal-menu .nav li a').each(function () {
            var $this = $(this);
            addActiveClass($this);
        });

        //Close other submenu in sidebar on opening any

        sidebar.on('show.bs.collapse', '.collapse', function () {
            sidebar.find('.collapse.show').collapse('hide');
        });

        //Change sidebar and content-wrapper height
        applyStyles();

        function applyStyles() {
            //Applying perfect scrollbar
            if (!body.hasClass('rtl')) {
                if ($('.settings-panel .tab-content .tab-pane.scroll-wrapper').length) {
                    const settingsPanelScroll = new PerfectScrollbar(
                        '.settings-panel .tab-content .tab-pane.scroll-wrapper'
                    );
                }
                if ($('.chats').length) {
                    const chatsScroll = new PerfectScrollbar('.chats');
                }
                if (body.hasClass('sidebar-fixed')) {
                    if ($('#sidebar').length) {
                        var fixedSidebarScroll = new PerfectScrollbar('#sidebar .nav');
                    }
                }
            }
        }

        $('[data-toggle="minimize"]').on('click', function () {
            if (body.hasClass('sidebar-toggle-display') || body.hasClass('sidebar-absolute')) {
                body.toggleClass('sidebar-hidden');
            } else {
                body.toggleClass('sidebar-icon-only');
            }
        });

        //checkbox and radios
        $('.form-check label,.form-radio label').append('<i class="input-helper"></i>');

        //Horizontal menu in mobile
        $('[data-toggle="horizontal-menu-toggle"]').on('click', function () {
            $('.horizontal-menu .bottom-navbar').toggleClass('header-toggled');
        });
        // Horizontal menu navigation in mobile menu on click
        var navItemClicked = $('.horizontal-menu .page-navigation >.nav-item');
        navItemClicked.on('click', function (event) {
            if (window.matchMedia('(max-width: 991px)').matches) {
                if (!$(this).hasClass('show-submenu')) {
                    navItemClicked.removeClass('show-submenu');
                }
                $(this).toggleClass('show-submenu');
            }
        });

        $(window).scroll(function () {
            if (window.matchMedia('(min-width: 992px)').matches) {
                var header = $('.horizontal-menu');
                if ($(window).scrollTop() >= 70) {
                    $(header).addClass('fixed-on-scroll');
                } else {
                    $(header).removeClass('fixed-on-scroll');
                }
            }
        });
    });
})(jQuery);
